<template>
  <b-card no-body>
      <b-card-header>
          <!-- title and legend -->
          <b-card-title class="mb-50">
              Ativos com maior tempo em uso ocioso
          </b-card-title>
          <!--/ title and legend -->

          <!-- datepicker -->
          <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr @onChange="onChangeCalendar" v-model="rangePicker" :config="{ mode: 'range' }"
                  class="form-control flat-picker bg-transparent border-0 shadow-none" />
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                  @click="validateRangePicker">
                  <feather-icon icon="SearchIcon" size="14" />
              </b-button>
          </div>
          <!-- datepicker -->
      </b-card-header>

      <b-card-body>
          <vue-apex-charts :key="componentKey" v-if="series[0].data.length > 0 && !isLoading" type="bar"
              height="300" :options="chartOptions" :series="series" />
          <div class="text-center" v-if="series[0].data.length == 0 && !isLoading">
              <p>Sem dados</p>
          </div>
          <div class="text-center" v-if="isLoading">
              <b-spinner variant="primary" label="Text Centered" />
          </div>
      </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BButton, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

import store from '@/store'
import dashboardStoreModule from './dashboardStoreModule'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
      BCard,
      BCardBody,
      BCardHeader,
      BSpinner,
      VueApexCharts,
      flatPickr,
      BCardTitle,
      BButton,
      ToastificationContent
  },

  data() {
      return {
          isLoading: true,
          apexChatData,
          componentKey: 1,
          severeData: null,
          rangePicker: ['2024-03-01', '2024-03-31'],
          toast: useToast(),
          chartOptions: {
              chart: {
                  id: 'vuechart-example',

              },
              colors: [
                  '#00cfe8',
              ],
              xaxis: {
                  categories: [],
              },
              dataLabels: {
                  enabled: false,
              },
          },
          series: [{
              name: 'Minutos',
              data: []
          }]

      }
  },
  beforeMount() {
      this.getStartDate()
      this.fetchIdle()
  },
  methods: {

      getStartDate() {
          // Get the current date
          var today = new Date();
          // First day of the current month
          var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          var firstDayFormatted = firstDay.toISOString().slice(0, 10); // Format the date

          // Last day of the current month
          var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          var lastDayFormatted = lastDay.toISOString().slice(0, 10); // Format the date

          this.rangePicker = [firstDayFormatted, lastDayFormatted];
      },

      onChangeCalendar(selectDate, datestr, instance) {
          console.log(selectDate)
          console.log(datestr)
          console.log(instance)
      },
      validateRangePicker() {
          console.log(this.rangePicker)
          if (this.rangePicker.length > 10) {
              this.rangePicker = this.rangePicker.split("to")
              console.log(this.rangePicker)
              this.fetchIdle()
          }
      },
      updateChart(data, categories) {
          this.series = [{
              name: 'Minutos',
              data: data
          }]
          //this.chartOptions.chart.colors = ['#ff9f43']
          this.chartOptions.xaxis.categories = categories
      },

      fetchIdle() {
          this.componentKey += 1
          this.isLoading = true

          store.dispatch('app-control-fleet/iddleusereport', {
              start: this.rangePicker[0],
              end: this.rangePicker[1],
          })
              .then((response) => {
                  this.isLoading = false
                  this.totalMinutesArray = []
                  this.identificadorArray = []
                  if (response && response.data && response.data.items) {
                      response.data.items.forEach(item => {
                          this.totalMinutesArray.push(item.totalMinutes)
                          this.identificadorArray.push(item.assetDescriptor)
                      })
                  }

                  this.updateChart(this.totalMinutesArray, this.identificadorArray)
              })
              .catch(error => {
                  this.isLoading = false
                  console.log(error)
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "Error fetching assets' list",
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      },
                  })
              })
          this.isLoading = false
      }
  },
  setup() {
      const CONTROL_FLEET_APP_STORE_MODULE_NAME = 'app-control-fleet'
      if (!store.hasModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)) store.registerModule(CONTROL_FLEET_APP_STORE_MODULE_NAME, dashboardStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
          if (store.hasModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)
      })

      const totalMinutesArray = ref([1])
      const identificadorArray = ref([1])

      return {
          CONTROL_FLEET_APP_STORE_MODULE_NAME,
          totalMinutesArray,
          identificadorArray
          //severeData,
          //rangePicker
      }
  }
}
</script>