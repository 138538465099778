import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSevere(ctx, queryParams) {
      console.log("veio")
      const { start, end, customerId } = queryParams;
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/queries/telemetry/severeusereport?DayStart=${start}&DayEnd=${end}&CustomerId=${customerId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIdle(ctx, queryParams) {
      const { start, end, customerId } = queryParams;
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/queries/telemetry/iddleusereport?DayStart=${start}&DayEnd=${end}&CustomerId=${customerId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFailureCount(ctx, queryParams) {
      const { start, end, customerId } = queryParams;
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/queries/telemetry/failurecountreport?DayStart=${start}&DayEnd=${end}&CustomerId=${customerId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    

  },

  //
}
